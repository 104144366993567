import React from 'react'
import Topbar from './Navbar'
import '../Components/Contact.css'
import { Col, Row } from 'react-bootstrap'
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Footer from './Footer';

function Contact() {
  return (
    <>
   
   <Topbar/>
     <div className='Contact-main'>
      <div className='Contact-banner-container'>
  </div>
        <div className='Contact-text'>
        <h5>Contact Us</h5>
      </div> 
      <div className='contact2'>
        <Row>
            <Col xs={12} md={4}>
                <div className='contact-card'>
                  <h4>Make a call to Us</h4>
                  <CallIcon style={{fontSize:"45px",marginLeft:"40%",marginTop:"20px",color:"#ffa62b"}}/>
                   <p>6542345667</p>
                   <p>6542345667</p>
                   <p>6542345667</p>

                </div>
            </Col>
            <Col xs={12} md={4}>
            <div className='contact-card'>
                <h4>Make Mail to US</h4>
                    <EmailIcon style={{fontSize:"45px",marginLeft:"40%",marginTop:"20px",color:"#ffa62b"}}/>
                    <p>maechlaair@gmail.com</p>
                    <p>maech@gmail.com</p>

                    </div>
            </Col>
            <Col xs={12} md={4}>
            <div className='contact-card'>
                <h4>Visit our Office</h4>
                    <LocationOnIcon style={{fontSize:"45px",marginLeft:"40%",marginTop:"20px",color:"#ffa62b"}}/>
                    <p> 1 Beverly Hills, Los Angeles, California, 90210,United States</p>

                    </div>
            </Col>
        </Row>
      </div>
      </div>
      <Footer/>
    </>
  )
}

export default Contact
