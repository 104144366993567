import React from 'react'
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import Topbar from './Navbar';
import '../Components/Service.css'
import { Col, Row } from 'react-bootstrap';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import FilterVintageIcon from '@mui/icons-material/FilterVintage';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import Footer from './Footer';
function Service() {
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
  
  
    const images = [
      {
        label: 'Chennai metro depot peb eraction',
        imgPath:
          '/Images/Picture1.jpg',
      },
      {
        label: 'Chennai metro depot peb eraction',
        imgPath:
          '/Images/Picture2.jpg',
      },
      {
        label: 'Chennai metro depot peb eraction',
        imgPath:
          '/Images/Picture3.jpg',
      },
      {
        label: 'Chennai metro depot peb eraction',
        imgPath:
          '/Images/Picture4.jpg',
      },

      {
        label: "Ansel safety equepment factory eraction",
        imgPath:
          '/Images/Picture5.jpg',
      },
      {
        label: 'Kia project penukonda',
        imgPath:
          '/Images/Picture6.jpg',
      },
      {
        label: 'Kia project penukonda',
        imgPath:
          '/Images/Picture7.jpg',
      },
      {
        label: 'Kia project penukonda',
        imgPath:
          '/Images/Picture8.jpg',
      },
    ];
  
    const maxSteps = images.length;
  
    const handleNext = () => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
  
    const handleStepChange = (step) => {
      setActiveStep(step);
    };
  return (
    <>
    <Topbar/>
    <div className='Service-main'>
      <div className='Service-banner-container'>
  </div>
        <div className='Service-text'>
        <h5>Our Services</h5>
      </div> 

      <div className='service2'>
        <h4>Specialitites of our services</h4>
        <br/>
        <Row>
          <Col xs={12} md={6}>
          <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
<ThumbUpOffAltIcon/>
        </ListItemAvatar>
        <ListItemText
          primary="Quality"
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
              </Typography>
              {" Satisfaction of customer,Zero defects,Value for money,High effenciency "}
            </React.Fragment>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
<AutoFixHighIcon/>
        </ListItemAvatar>
        <ListItemText
          primary="Productivity"
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
              </Typography>
              {"Time management, accuracy, safework"}
            </React.Fragment>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
<FilterVintageIcon/>     
   </ListItemAvatar>
        <ListItemText
          primary="Reliablity"
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
              </Typography>
              {'Trust, consistancy, durability'}
            </React.Fragment>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
<InsertEmoticonIcon/>     
   </ListItemAvatar>
        <ListItemText
          primary="Statisfaction"
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
              </Typography>
              {'Delighted customer ,providing expected result'}
            </React.Fragment>
          }
        />
      </ListItem>
    </List>
          </Col>
          <Col xs={12} md={6}>
            <img src='/Images/service2.webp'/>
          </Col>
         
        </Row>
      </div>
      <Row>
      <Col xs={12} md={6}>
      <Box sx={{ maxWidth: "100%", flexGrow: 1,padding:"30px" }}>
      <Paper
        square
        elevation={0}
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: 50,
          pl: 2,
          bgcolor: 'background.default',
        }}
      >
        <Typography>{images[activeStep].label}</Typography>
      </Paper>
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {images.map((step, index) => (
          <div key={step.label}>
            {Math.abs(activeStep - index) <= 2 ? (
              <Box
                component="img"
                sx={{
                  height: 255,
                  display: 'block',
                  maxWidth: 1700,
                  overflow: 'hidden',
                  width: '100%',
                }}
                src={step.imgPath}
                alt={step.label}
              />
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
    </Box>
          </Col>

          <Col xs={12} md={6}>
            <div className='imgs-text'>
              <h4>WE ARE...?</h4>

              <p>Constantly assess the needs, realities and values of the customer and set goals to satisfy their needs.Continually innovate, develop and adopt state-of-the-art technologies, methodologies and materials to deliver customer satisfaction through better, faster and cheaper construction services
                .Continually develop and maintain a robust supply chain that will help us deliver value to the customer on time and to expectations
Continually improve the competence of our people through education and by inculcating strict principles of conduct and responsibility, high standards of performance, and respect for individuals and their work
              </p>
              </div>

              </Col>
      </Row>
<div className='service3'>
<h4> WHAT WE DO ??</h4>

<Row>
  <Col xs={12} md={4} lg={3}>
  <h6>Eraction</h6>
              <ul >
  <li>Light and Heavy Structure

</li>
  <li>Chimney Insta\Nation

</li>
  <li>Equipment Erection
Duct, Piping, Vessel
« Rotary Kiln

</li>
<li>ESP, Stacker cum Rec\aimer
Raw Nill, Col Nile, Conveyor, Fly Ash
</li>
<li>Bucket Elevator
</li>

</ul> 
  </Col>

  <Col xs={12} md={4} lg={3}>
  <h6>Fabrication</h6>
              <ul >
  <li>Light and Heavy Structure work
</li>
  <li>Duct, Piping, Tank, Hopper, Chimney
</li>
  <li>PEB Fabrication
</li>
<li>PIPINS WORKS </li>
<li>MILLWRIGHT JOBS </li>

</ul> 
</Col>



  <Col xs={12} md={4} lg={3}>
  <h6>Railway Works</h6>
              <ul >
  <li>« FOB & ROB Fabrication and Erection works
Railway Platform

</li>
  <li>	Fabrication and Erection of Launching system of Heavy girder x Supporting staging erection and dismantling work

</li>

</ul>
  </Col>

  <Col xs={12} md={4} lg={3}>
  <h6>Roofing and sheeting works</h6>
              <ul >
  <li>Fabrication and Installation work of all Light and Heavy Roof Structures (Pipe, Square Tube)

</li>
  <li>Sheeting Work

</li>
  <li>Partition and extension of a\l Heavy and Light Structure

</li>

</ul> 
</Col>
</Row>

             

</div>




       
            
    </div>
    <Footer/>
    </>

  )
}

export default Service
