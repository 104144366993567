import React from 'react'
import Topbar from './Navbar'
import '../Components/About.css'
import { Col, Row } from 'react-bootstrap'
import ArrowDropDownCircleOutlinedIcon from '@mui/icons-material/ArrowDropDownCircleOutlined';
import Footer from './Footer';


function About() {


  return (
    <>
        <Topbar/>
     <div className='About-main'>
      <div className='About-banner-container'>
  </div>
        <div className='About-text'>
        <h5>About Us</h5>
      </div> 
      <div className='about2'>
        <Row>
          <Col xs={12} md={6}>
          <div className='about-text'>
            <div className='about-head' style={{display:"flex"}}>
            <h2>What we are ?</h2>
           <ArrowDropDownCircleOutlinedIcon style={{fontSize:"30px",marginLeft:"30px",marginTop:"10px",color:"gray"}}/>
            </div>
          
           <p>We MECHLAIR ENGINEERING CONSTRUCTION
Pvt. Ltd. Directed by three well experienced and highly qUalified personalities. Managing hardworking and sincere human resoUrces and with ultra-modern types of machiner- ies and equipment. ThUs specialized in the field of Fabrication, Erection, Commissioning, Maintenance of Mechanical types of machin- ery and plants. Such as cement, steel, chemi- cals, sugar, fertilizers,refineries, thermal and hydro power indUstries and railway project, roofing works, firefighting works, throUghoUt India and as well as abroad.

We have highly  experienced and skilled workmen and well-trained  and experienced Engineers and staff.  We have more than 18 years of experience in this field. We assured superior qUality and expected results through- 0Ut our entire works.
</p>

        </div>
          </Col>

          <Col xs={12} md={6}>
          <div className='about-img'>
          <img src='/Images/about2.jpg'/>

        </div>
          </Col>
        </Row>
    

      </div>
      <div className='about3'>
        <div className='vision-details'>
          <Row>
            <Col xs={12} md={6}>
              <div className='sub-details'>
              <h4 style={{color:"#ffa62b"}}>MISSION</h4>
              <p>TO Satisfy our customer by providing valued construction solutions using our expertise in the institution and maintenance of mechanical fields and helping them to achieve high profit and goodwill for them.
</p>

              </div>
            </Col>
            <Col xs={12} md={6}>
            <div className='sub-details'>
              <h4  style={{color:"#ffa62b"}}>VISION</h4>
              <p>Be global by keeping the best quality, safety and good relationship.

We will be the most valued business partners to all our customers
</p>

              </div>
            </Col>
          </Row>

        </div>

      </div>
      </div>
     <Footer/>
      
    </>
  )
}

export default About
