import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
// import { HelmetProvider } from 'react-helmet-async';  // Import HelmetProvider

ReactDOM.createRoot(document.getElementById('root')).render(
    <BrowserRouter>
          <App />
    </BrowserRouter>
 , document.getElementById('root')
);

reportWebVitals();