import React, { useState, useEffect } from 'react';
import '../Components/Navbar.css'
import MenuIcon from '@mui/icons-material/Menu';

import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';

function Topbar() {
    const [isOpen, setIsOpen] = useState(false);
    const [showMenuToggle, setShowMenuToggle] = useState(false);

    
  const navigate=useNavigate()


  const handleAbout=()=>{
   navigate('/about')
  }

  const handleService=()=>{
    navigate('/service')
  }


  const handleContact=()=>{
    navigate('/contact')
  }
  const handleHome=()=>{
    navigate('/')
  }

 const toggleNav = () => {
   setIsOpen(!isOpen);
 };

 useEffect(() => {
  const handleResize = () => {
      setShowMenuToggle(window.innerWidth <= 1150);
  };

  handleResize(); // Call it initially
  window.addEventListener('resize', handleResize);

  return () => {
      window.removeEventListener('resize', handleResize);
  };
}, []);

 

  return (
    <div className='Top' >
              <div className='nav-main'>
    <div className='navbar' >
      <img src='Images/Logo.gif' />
      {!isOpen && (

<ul className={`nav-items`}>  
<p onClick={handleHome} > Home </p>
<p onClick={handleAbout} > About </p>
<p onClick={handleService}>Services</p>


        <p onClick={handleContact}>Contact</p>
   
      </ul>
     )}
     {showMenuToggle && (
         <div className='menu-toggle' onClick={toggleNav}>
             <MenuIcon style={{ color: 'white', marginLeft: '-80px', fontSize: '2rem' }} />
         </div>
     )}
    
 </div>
 {isOpen && (
  
  <div className='nav-items-bottom'>
    <p onClick={handleHome}>Home</p>
    {/* <p onClick={handleService}> <img src='/Images/cross-border.svg'/> Services </p> */}
    <p onClick={handleAbout}> About </p>
    <p onClick={handleService}>Services</p>

    <p onClick={handleContact}> Contact </p>
    <div className='close-toggle' onClick={toggleNav}>
      <CloseIcon style={{ color: 'black', fontSize: '1.5rem' }} />
    </div>
  </div>
)}

    </div>
      
    </div>
  )
}

export default Topbar
