import React from 'react';
import { Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from './Components/Navbar';
import Home from './Components/Home';
import Footer from './Components/Footer';
import About from './Components/About';
import Contact from './Components/Contact';
import Service from './Components/Service';

function App() {
  return (
    // <Router>
      <div className="App">
        <Routes>
          <Route exact path='/nav' element={<Navbar />} />
          <Route exact path='/' element={<Home />} />
          <Route exact path='/footer' element={<Footer />} />
          <Route exact path='/about' element={<About />} />
          <Route exact path='/contact' element={<Contact />} />
          <Route exact path='/service' element={<Service />} />





        </Routes>
      </div>
  );
}

export default App;
