import React from 'react'
import '../Components/Footer.css'
import { Col, Row } from 'react-bootstrap'
import HomeIcon from '@mui/icons-material/Home';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
function Footer() {
  return (
    <div className='footer-main'>
      <div className='footer'>
        <Row>
          <Col xs={12} md={3}>
            <div className='company' >
            <img src='/Images/home2-img.jpg'/>
            <h5> Mechlair pvt ltd</h5>
            </div>
            <br/>
            <div className='address' style={{display:"flex"}}>
            <HomeIcon style={{fontSize:"20px",color:"#ffa62b"}} />
             <p> 1 Beverly Hills, Los Angeles, California, 90210,United States</p>

            </div>
            <div className='address' style={{display:"flex"}}>
            <CallIcon style={{fontSize:"20px",color:"#ffa62b"}} />
             <p> 4974334567</p>
            </div>
            <div className='address' style={{display:"flex"}}>
            <EmailIcon style={{fontSize:"20px",color:"#ffa62b"}} />
            <p> Mechlair@gmail.com</p>


            </div>
           
          </Col>
          <Col xs={12} md={3}>
            <div className='pages-list'>
            <h6>useful links</h6>
            <p>Home</p>
            <p>about</p>
            <p>service</p>
            <p>mission</p>
            <p>vision</p>


            </div>
          </Col>
          <Col xs={12} md={3}>
          <div className='pages-list'>
            <h6>Services</h6>
            <p>Fabrication</p>
            <p>Erection</p>
            <p>Millwright jobs</p>
            <p>Pipins works</p>
            <p>Railway works</p>
            <p>Roofing and sheeting works</p>
            <p>Fire fighting works</p>



            </div>
          </Col>
          <Col xs={12} md={3}>
          <div className='pages-list'>
          <div className='address' style={{display:"flex"}}>
            <InstagramIcon style={{fontSize:"20px",color:"#ffa62b"}} />
             <p> MEchlair.in</p>

            </div>
            <div className='address' style={{display:"flex"}}>
            <FacebookIcon style={{fontSize:"20px",color:"#ffa62b"}} />
             <p> MEchlair.in</p>
            
            </div>
            <br/>
             <p> We offers its customers everything they need to effortlessly plan and carry out building projects more quickly than ever before</p>


            </div>
          </Col>
        </Row>
      </div>
      
    </div>
  )
}

export default Footer
