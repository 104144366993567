import React from 'react'
import Topbar from './Navbar'
import '../Components/Home.css'
import { Button, Col, Row } from 'react-bootstrap'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import EngineeringIcon from '@mui/icons-material/Engineering';
import ConstructionIcon from '@mui/icons-material/Construction';
import FoundationIcon from '@mui/icons-material/Foundation';
import Footer from './Footer';
import DoneIcon from '@mui/icons-material/Done';


function Home() {
  return (
    <>
    <Topbar/>
     <div className='Home-main'>
      <div className='Home-banner-container'>
  </div>
        <div className='Home-text'>
        <h5>WE WILL BE <span className='span'> HAPPY <br/></span>TO TAKE CARE OF YOUR WORK</h5>
      </div>
      <div className='home2'>
        <Row>
          <Col xs={12} md={6}>
          <div className='home2-image'>

            <img src='/images/home2-img.jpg'/>
            </div>

          </Col>
         
          <Col xs={12} md={6}>
            <div className='home2-text'>
              <h5 className='span'>Welcome to Mechlair</h5>
              <h2 style={{fontWeight:"600"}}>Quality and Affordable Constructor</h2>
              <p>We MECHLAIR ENGINEERING CONSTRUCTION
              Pvt. Ltd. Directed by three well experienced and highly qUalified personalities. Managing hardworking and sincere human resoUrces and with ultra-modern types of machiner- ies and equipment. ThUs specialized in the field of Fabrication, Erection, Commissioning, Maintenance of Mechanical types of machin- ery and plants. 
              </p> 
             <h2>What we offer</h2>
             <p style={{textAlign:"start",marginLeft:"20px"}}><DoneIcon style={{color:"green",fontSize:"23px",fontWeight:"800"}}/>   Quality Servicing</p>

             <p style={{textAlign:"start",marginLeft:"20px"}}><DoneIcon style={{color:"green",fontSize:"23px",fontWeight:"800"}}/>   Expert Workers</p>
                <p style={{textAlign:"start",marginLeft:"20px"}}><DoneIcon style={{color:"green",fontSize:"23px",fontWeight:"800"}}/>   Modern Equipment</p>
              <div className='arrow-div' style={{marginLeft:"40px",marginTop:"50px"}}>
<p style={{color:"white",marginLeft:"10px",marginTop:"5px",
  fontSize:"12px"
}}>Our Services</p>
<ArrowCircleRightIcon style={{color:"white",marginLeft:"25px",fontSize:"30px"}}/>

     </div>
            </div>
          </Col>

        </Row>
      </div>

      <div className='home3'>
        <Row>
          <Col xs={12} md={12} lg={4}>
            <div className='color-div'>
              <h2>25</h2>
              <h4>Y E A R S</h4>
              <h5>E X P E R I E N C E</h5>

            </div>
          </Col>
          <Col xs={12} md={12} lg={4}>
            <div className='center-div'>
              <p>W  E  L  C  O  M  E  !</p>
              <h3>SECRETS OF SUCCESS</h3>
              <h2>OUR JOURNEY</h2>
            </div>
          </Col>
          <Col xs={12} md={12} lg={4}>
            <div className='text-end'>
              <p>We MECHLAIR ENGINEERING CONSTRUCTION
              Pvt. Ltd. Directed by three well experienced and highly qUalified personalities. Managing hardworking and sincere human resoUrces and with ultra-modern types of machiner- ies and equipment. ThUs specialized in the field of Fabrication, Erection, Commissioning, Maintenance of Mechanical types of machin- ery and plants. 

              </p>
            </div>
          </Col>
        </Row>

      </div>
      <div className='home4'>
        <Row>
          <Col xs={12} md={4}>
            <div className='icons'>
            <EngineeringIcon style={{fontSize:"80px", color:"rgba(255, 255, 255, 0.7)",zIndex:"1",position:"relative"}}/>

            </div>

          </Col>
          <Col xs={12} md={4}>
          <div className='icons'>
            <ConstructionIcon style={{fontSize:"80px", color:"rgba(255, 255, 255, 0.7)",zIndex:"1",position:"relative"}}/>

            </div>
          </Col>
          <Col xs={12} md={4}>
          <div className='icons'>
            <FoundationIcon style={{fontSize:"80px", color:"rgba(255, 255, 255, 0.7)",zIndex:"1",position:"relative"}}/>

            </div>
          </Col>
          
        </Row>
        
     
      </div>
      <div className='home5'>
          <Row>
            <Col xs={12} md={6}>
              <div className='home5-text'>
               <div className='head-text'>
                <h4>OUR MISSION AND VISION</h4>
               </div>
               <div className='head-p'>
               <p>TO Satisfy our customer by providing valued construction solutions using our expertise in the institution and maintenance of mechanical fields and helping them to achieve high profit and goodwill for them.
              </p>
              <div className='arrow-div' style={{marginTop:"50px"}}>
<p style={{color:"white",marginLeft:"10px",marginTop:"5px"}}>view more</p>
<ArrowCircleRightIcon style={{color:"white",marginLeft:"42px",fontSize:"27px",marginTop:"2px"}}/>

     </div>               </div>
              
              </div>
            </Col>

            <Col xs={12} md={6}>
              <div className='home5-image'>
                <img src='/Images/home5.webp'/>
                
              </div>
            </Col>
          </Row>
        </div>
      <Footer/>
      </div>
      </>
  )
}

export default Home
